import React, { useContext } from 'react'

import UploadTable from './UploadTable'
import UploadButton from './UploadButton'
import { PSAPDetailContext } from '../../wrappers/PSAPDetailContext'

const DetailPage = () => {
    const { PSAP } = useContext(PSAPDetailContext)

    if (!PSAP) {
        return null
    }

    return (
        <>
            <div className="section">
                <h2 className="title is-2 has-text-centered">
                    {PSAP.Name}
                </h2>
                <div className="has-text-centered">
                    {PSAP.ReadOnly ? null : <UploadButton />}
                </div>
                <div className="section">
                    {PSAP.Uploads && PSAP.Uploads.length ? (
                        <UploadTable
                            uploads={PSAP ? PSAP.Uploads : null}
                            readOnly={PSAP.ReadOnly}
                        />
                    ) : (
                        <h3 className="title is-4 has-text-centered">
                            No files have been uploaded to {PSAP.Name}.
                        </h3>
                    )}
                </div>
            </div>
        </>
    )
}

export default DetailPage
