import React, { createContext, useCallback } from 'react'
import { useAuth } from 'oidc-react'

const APIRequestContext = createContext(null)

const APIRequestContextProvider = ({ children }) => {
    const { userManager } = useAuth()

    const authenticatedFetch = useCallback(async (endpoint, options) => {
        const u = await userManager.getUser()
        let fetchOptions = {}
        if (options) {
            fetchOptions = {
                ...options,
                headers: {
                    ...options.headers,
                    Authorization: `Bearer ${u ? u.access_token : null}`,
                },
            }
        } else {
            fetchOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${u ? u.access_token : null}`,
                },
            }
        }
        return fetch(endpoint, fetchOptions)
    }, [userManager])

    return (
        <APIRequestContext.Provider
            value={{authenticatedFetch}}
        >
            {children}
        </APIRequestContext.Provider>
    )
}

export { APIRequestContext }
export default APIRequestContextProvider
