import React, { useMemo, useState } from 'react'
import { useTable, useSortBy, usePagination, useFilters } from 'react-table'
import { Link } from 'react-router-dom'
import {
    FaAngleDown,
    FaAngleUp,
    FaChevronCircleRight,
    FaChevronCircleLeft,
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaSearch,
} from 'react-icons/fa'
import { IncludesFilter, filterTypes } from '../../elem/GridFilters'

const PSAPTable = ({ psaps }) => {
    const [visibleSearch, setVisibleSearch] = useState([])

    const handleSearch = (column) => {
        const id = column.id
        if (id) {
            visibleSearch.includes(id)
                ? setVisibleSearch(visibleSearch.filter((x) => x !== id))
                : setVisibleSearch([...visibleSearch, id])
        }
    }

    const calcTableDate = () => {
        const tableData = []
        psaps.forEach((psap) => {
            if (psap.Uploads.length) {
                const lastUploadDate = psap.Uploads.reduce((a, b) =>
                    a.UploadDate > b.UploadDate ? a : b
                )
                const successfullUploads = psap.Uploads.filter(
                    (upload) =>
                        upload.MergeStatus === 2 &&
                        upload.ValidationStatus === 2 &&
                        upload.ArchiveStatus === 2
                )
                const lastSuccesfulUpload = successfullUploads.length
                    ? successfullUploads.reduce((a, b) =>
                          a.UploadDate > b.UploadDate ? a : b
                      )
                    : null
                tableData.push({
                    ...psap,
                    lastUploadDate: lastUploadDate.UploadDate.slice(0, 10),
                    lastMerge: lastUploadDate.MergeStatus,
                    lastValidation: lastUploadDate.ValidationStatus,
                    lastSuccessfull: lastSuccesfulUpload
                        ? lastSuccesfulUpload.UploadDate.slice(0, 10)
                        : 'N/A',
                })
            } else {
                tableData.push({
                    ...psap,
                    lastUploadDate: 'N/A',
                    lastMerge: 'N/A',
                    lastValidation: 'N/A',
                    lastSuccessfull: 'N/A',
                })
            }
        })
        return tableData
    }

    const DetailCell = ({ value, id }) => {
        return (
            <>
                <Link
                    // className="tableLink"
                    to={{
                        pathname: `/psap-detail/${id}`,
                    }}
                >
                    {value}
                </Link>
            </>
        )
    }

    const MergeCell = ({ value }) =>
        value === 1
            ? 'Pending'
            : value === 2
            ? 'Complete'
            : value === 3
            ? 'Failed'
            : 'N/A'

    const ValidationCell = ({ value }) =>
        value === 1
            ? 'Pending'
            : value === 2
            ? 'Valid'
            : value === 3
            ? 'Invalid'
            : value === 4
            ? 'Failed'
            : 'N/A'

    const createColumns = () => [
        {
            Header: 'PSAP Name',
            accessor: 'Name',
            Cell: ({ cell, row }) => (
                <DetailCell value={cell.value} id={row.original.Id} />
            ),
        },
        {
            Header: 'Last Upload Validation Status',
            accessor: 'lastValidation',
            disableFilters: true,
            Cell: ({ cell }) => <ValidationCell value={cell.value} />,
        },
        {
            Header: 'Last Upload Merge Status',
            accessor: 'lastMerge',
            disableFilters: true,
            Cell: ({ cell }) => <MergeCell value={cell.value} />,
        },
        {
            Header: 'Last Upload Date',
            accessor: 'lastUploadDate',
            disableFilters: true,
        },
        {
            Header: 'Last Successful Upload Date',
            accessor: 'lastSuccessfull',
            disableFilters: true,
        },
    ]

    const defaultColumn = useMemo(
        () => ({
            Filter: IncludesFilter,
        }),
        []
    )

    const columns = useMemo(() => createColumns(), [])
    const tableData = useMemo(
        () => (psaps && psaps.length ? calcTableDate() : []),
        [psaps]
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data: tableData,
            defaultColumn,
            filterTypes,
            initialState: {
                pageIndex: 0,
                pageSize: 10,
                // sortBy: [
                //     {
                //         id: 'Name',
                //         desc: false,
                //     },
                // ],
            },
        },
        useFilters,
        useSortBy,
        usePagination
    )

    return (
        <div className="gridWrapper is-centered">
            <table {...getTableProps()} className="table is-fullwidth">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    className="has-text-grey-dark"
                                >
                                    <div className="is-flex is-align-items-center is-flex-wrap-wrap">
                                        <span
                                            {...column.getSortByToggleProps()}
                                        >
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <FaAngleDown />
                                                ) : (
                                                    <FaAngleUp />
                                                )
                                            ) : (
                                                ''
                                            )}
                                            {column.render('Header')}
                                        </span>
                                        {column.canFilter ? (
                                            <span
                                                className="searchIcon"
                                                onClick={() =>
                                                    handleSearch(column)
                                                }
                                            >
                                                <FaSearch />
                                            </span>
                                        ) : null}
                                        <span
                                            style={
                                                visibleSearch.length &&
                                                visibleSearch.indexOf(
                                                    column.id
                                                ) > -1
                                                    ? null
                                                    : { display: 'none' }
                                            }
                                        >
                                            {column.canFilter
                                                ? column.render('Filter')
                                                : null}
                                        </span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr
                                {...row.getRowProps()}
                                className="row has-text-grey-dark"
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div
                className="pagination-list"
                style={{ justifyContent: 'center' }}
            >
                <div
                    className={!canPreviousPage ? 'has-text-grey' : ''}
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                >
                    <FaAngleDoubleLeft />
                </div>{' '}
                <div
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    className={!canPreviousPage ? 'has-text-grey' : ''}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <FaChevronCircleLeft />
                </div>{' '}
                <span>
                    <th className="">
                        Page{' '}
                        <strong className="has-text-grey-dark">
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </th>
                </span>
                <div
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    className={!canNextPage ? 'has-text-grey' : ''}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <FaChevronCircleRight />
                </div>{' '}
                <div
                    className={!canNextPage ? 'has-text-grey' : ''}
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <FaAngleDoubleRight />
                </div>{' '}
            </div>
        </div>
    )
}

export default PSAPTable
