import React, { useEffect, createContext, useState } from 'react'
import { AuthProvider, useAuth, UserManager } from 'oidc-react'

import { getRoles } from '../../utils/user/permissions'
import withConfig from '../wrappers/withConfig'
import settings from '../../utils/constants/userSettings'

const UserContext = createContext(null)

const AppUserContextProvider = withConfig(({ loadingUser, children }) => {
    const { userManager: client } = useAuth()
    const [roles, setRoles] = useState("")
    const [ user, setUser ] = useState(null)
    
    // when the user changes, update roles list
    useEffect(() => {
        setRoles(getRoles(user))
    }, [user])

    useEffect(() => {
        const getUser = async () => {
            try {
                const u = await client.getUser()
                setUser(u)
            } catch (e) {
                console.log('UserContext: Error getting user -- ', e)
            }
        }
        if (client) {
            getUser()
        }
    }, [client])

    return (
        <UserContext.Provider
            value={{
                user,
                roles,
                client,
                loadingUser
            }}
        >
            {children}
        </UserContext.Provider>
    )
})

const UserContextProvider = ({ children }) => {
    const userManager = new UserManager(settings)

    return (
        <AuthProvider
            userManager={userManager}
            autoSignIn={false}
        >
            <AppUserContextProvider>{children}</AppUserContextProvider>
        </AuthProvider>
    )
}

export { UserContext }
export default UserContextProvider
