const urls = {
    home: '/',
    login: '/login',
    userCallback: '/user/callback',
    newRegistration: `${process.env.REACT_APP_OIDC_REGISTRATION_URL}`,
    changePassword: `${process.env.REACT_APP_OIDC_CHANGE_PASSWORD_URL}`,
    manageUsers: `${process.env.REACT_APP_USER_MANAGEMENT_URL}`,
    psapDetail: '/psap-detail/:PSAPId'
}

export default urls