import React from 'react'
import {matchSorter} from 'match-sorter'


const IncludesFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
  }) => {
    const count = preFilteredRows.length
  
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} PSAPs...`}
      />
    )
  }

  const fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  }
  
  fuzzyTextFilterFn.autoRemove = val => !val

  
  const filterTypes = 
      () => ({
          fuzzyText: fuzzyTextFilterFn,
          
          text: (rows, id, filterValue) => {
              return rows.filter((row) => {
                  const rowValue = row.values[id]
                  return rowValue !== undefined
                  ? String(rowValue)
                  .toLowerCase()
                  .startsWith(String(filterValue).toLowerCase())
                  : true
                })
            },
        })

export { IncludesFilter, filterTypes, fuzzyTextFilterFn }