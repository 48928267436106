import React from 'react'

const Modal = ({ toggle, header, footer, response, body, className }) => {
    return (
        <>
            <div className={`${className} modal`} id="modal" />
            <div className="modal-background" onClick={toggle}></div>
            <div className="modal-card">
                <header className="modal-card-head primary">
                    <p className="modal-card-title has-text-primary">
                        {header}
                    </p>
                    <button
                        className={response ? 'hidden' : 'delete'}
                        aria-label="close"
                        onClick={toggle}
                    ></button>
                </header>
                <div className="modal-card-body">{body}</div>
                <footer
                    className={`modal-card-foot primary ${
                        response ? 'center' : ''
                    }`}
                >
                    {footer}
                </footer>
            </div>
        </>
    )
}

export default Modal
