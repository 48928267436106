import React, { useContext, useState } from 'react'
import Modal from '../../elem/Modal'
import useModal from '../../wrappers/useModal'
import UploadForm from './UploadForm'
import { PSAPDetailContext } from '../../wrappers/PSAPDetailContext'
import { PSAPContext } from '../../wrappers/PSAPContext'

const UploadButton = () => {
    const { PSAP } = useContext(PSAPDetailContext)
    const { setNewObject } = useContext(PSAPContext)
    const { isShowing, toggle } = useModal()
    const [response, setResponse] = useState(null)
    const [uploading, setUploading] = useState(false)

    const openModal = () => {
        setResponse(null)
        toggle()
        setNewObject(false)
    }

    const uploadingFile = (status) => {
        setUploading(status)
    }

    const handleUpload = (response) => {
        setResponse(response)
    }

    const closeForm = () => {
        toggle()
        setNewObject(true)
    }

    const text =
        response && typeof response === 'number'
            ? 'Upload Successful!'
            : 'File upload failed.'

    return isShowing ? (
        <Modal
            isShowing={isShowing}
            toggle={toggle}
            header={`Upload New File for ${PSAP.Name}`}
            footer={
                !response ? (
                    <>
                        <button
                            type="submit"
                            form="uploadForm"
                            className="button solidg"
                            disabled={uploading}
                        >
                            Save Upload
                        </button>
                        <button
                            type="cancel"
                            onClick={toggle}
                            className="button is-outlined"
                        >
                            Cancel
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            type="button"
                            onClick={closeForm}
                            className="button is-primary"
                        >
                            Ok
                        </button>
                    </>
                )
            }
            response={response}
            body={
                !response ? (
                    <UploadForm
                        handleUpload={handleUpload}
                        uploading={uploading}
                        uploadingFile={uploadingFile}
                    />
                ) : (
                    <>
                        <p>{text}</p>
                    </>
                )
            }
        />
    ) : (
        <>
            <div className="buttonWrapper">
                <button className="button wg" onClick={openModal}>
                    Upload a New File
                </button>
            </div>
        </>
    )
}

export default UploadButton
