import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../wrappers/UserContext'
import { Redirect } from 'react-router-dom'
import { checkRole, getRoles, hasApplicationAccess } from '../../../utils/user/permissions'
import urls from '../../../utils/constants/urls'

const RestrictedAccessPage = ({ client }) => {
    return (
        <div>
            <section className="section">
                <h2 className="title is-2 has-text-centered">Unauthorized</h2>
                <div className="section">
                    <h2
                        className="title is-3 has-text-centered"
                        style={{ paddingTop: '5%' }}
                    >
                        You do not have permission to view this page. Please
                        contact your system administrator or 
                        <br/>
                        <a
                            onClick={function () {
                                client.signoutRedirect()
                            }}
                        >
                            log out
                        </a>{' '}
                        and log back in to access this application.
                    </h2>
                </div>
            </section>
        </div>
    )
}

const RestrictedAccess = ({ allowedRoles, children }) => {
    const { client } = useContext(UserContext)
    const [hasAccess, setHasAccess] = useState(true)
    const [hasAccessButNoRoles, setHasAccessButNoRoles] = useState(false)

    useEffect(() => {
        const checkClientAccess = async (allowedRoles) => {
            try {
                await client.getUser().then((user) => {
                    const roles = getRoles(user)
                    if (roles == '' && hasApplicationAccess(user)) {
                        setHasAccessButNoRoles(true)
                    } else if (
                        allowedRoles.some((role) => checkRole(role, roles))
                    ) {
                        setHasAccess(true)
                    } else {
                        setHasAccess(false)
                    }
                })
            } catch (e) {
                setHasAccess(false)
            }
        }
        checkClientAccess(allowedRoles)
    }, [client, allowedRoles])

    if (hasAccessButNoRoles) {
        return <RestrictedAccessPage client={client} />
    }
    if (hasAccess) {
        return children
    } else {
        return <Redirect to={urls.login} />
    }
}

export default RestrictedAccess
