import React, { createContext, useEffect, useContext, useState } from 'react'

import toast from '../elem/Toast'
import { APIRequestContext } from './APIRequestContext'
import withConfig from './withConfig'

const PSAPContext = createContext(null)

const PSAPContextProvider = withConfig(({ config, children }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [ loading, setLoading ] = useState(true)
    const [ PSAPs, setPSAPs ] = useState([])
    const [ PSAPUpdates, setPSAPUpdates ] = useState([])
    const [newObject, setNewObject] = useState(null)
    const { API_URL } = config

    // fetch the uploads a user has access to
    useEffect(() => {
        setLoading(true)
        authenticatedFetch(`${API_URL}/PSAP/UserPSAPs`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setPSAPs(response)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(setLoading(false))
    }, [API_URL, authenticatedFetch, newObject])

    useEffect(() => {
        setLoading(true)
        authenticatedFetch(`${API_URL}/PSAP/Updates`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setPSAPUpdates(response)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(setLoading(false))
    }, [API_URL, newObject, authenticatedFetch])

    return (
        <PSAPContext.Provider
            value={{
                loading,
                PSAPs,
                PSAPUpdates,
                newObject,
                setNewObject
            }}
        >
            {children}
        </PSAPContext.Provider>
    )
})

export { PSAPContext }
export default PSAPContextProvider