import React, { useContext } from 'react'
import PSAPTable from './UserPSAPTable'
import PSAPStatusTable from './AllPSAPTable'
import { UserContext } from '../../wrappers/UserContext'
import { PSAPContext } from '../../wrappers/PSAPContext'
import SimpleSpinner from '../../elem/Spinner'

const PSAPListPage = () => {
    const { user } = useContext(UserContext)
    const { PSAPUpdates, PSAPs, loading } = useContext(PSAPContext)

    if (loading || !user) {
        return <SimpleSpinner />
    }

    if (!PSAPs || !PSAPs.length || !PSAPUpdates || !PSAPUpdates.length) {
        return null
    }

    return (
        <>
            <section className="section">
                <h2 className="title is-2 has-text-centered">My PSAPs</h2>
                
                <div className="section">
                    <PSAPTable psaps={PSAPs} />
                    <br/>
                    <h2 className="title is-3 has-text-centered" style={{paddingTop: '5%'}}>PSAP Status List</h2>
                    <PSAPStatusTable psaps={PSAPUpdates} />
                </div>
            </section>
        </>
    )
}

export default PSAPListPage