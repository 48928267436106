import React, { useContext, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import withConfig from '../../wrappers/withConfig'
import toast from '../../elem/Toast'
import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { PSAPDetailContext } from '../../wrappers/PSAPDetailContext'

const UploadForm = ({ config, handleUpload, uploading, uploadingFile }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { register, handleSubmit, errors } = useForm()
    const { PSAP } = useContext(PSAPDetailContext)
    const [fileName, setFileName] = useState(null)
    const { API_URL } = config
    const [response, setResponse] = useState(null)

    useEffect(() => {
        if (response) {
            handleUpload(response)
        }
    }, [response, handleUpload])

    const POST = (upload) => {
        const formData = new FormData()
        formData.append('File', upload.File)
        formData.append('FileName', upload.FileName)
        formData.append('PSAPId', upload.PSAPId)
        return {
            method: 'POST',
            mode: 'cors',
            headers: {
                // 'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: formData,
        }
    }

    const uploadFile = (file) => {
        authenticatedFetch(
            `${API_URL}/upload/create`,
            POST({
                File: file,
                FileName: fileName.slice(0, fileName.lastIndexOf('.')),
                PSAPId: PSAP.Id,
            })
        )
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setResponse(response)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Create Page:' +
                        (e.message ? e.message : 'File upload failed'),
                })
                setResponse('Failed')
            })
            .finally(() => {
                uploadingFile(false)
            })
    }
    
    const handleChange = (event) => {
        setFileName(event.target.files[0].name)
    }

    const onSubmit = (data) => {
        uploadingFile(true)
        const file = data.File[0]
        uploadFile(file)
    }

    return (
        <>
            <form
                id="uploadForm"
                onSubmit={handleSubmit(onSubmit)}
                className="form"
            >
                <div className="file has-name">
                    <label className="file-label">
                        <input
                            onChange={handleChange}
                            className="file-input"
                            type="file"
                            name="File"
                            accept=".zip"
                            disabled={uploading || response ? true : false}
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Please select a file for upload',
                                },
                            })}
                        />
                        <span className="file-cta">
                            <span className="file-icon">
                                <i className="fas fa-upload"></i>
                            </span>
                            <span className="file-label">
                                {uploading ? 'Uploading' : 'Upload a File'}
                            </span>
                        </span>
                        {fileName ? (
                            <span className="file-name has-text-primary">
                                {fileName}
                            </span>
                        ) : null}
                    </label>
                </div>
                {errors.FileName ? (
                    <p className="help is-danger has-text-left">
                        {errors.FileName.message}
                    </p>
                ) : null}
                <p className="help has-text-left has-text-primary">       Upload must be .zip file.</p>
                <p className="help has-text-left">
                    Upon successful upload and validation the file will be
                    merged into the state-wide NG911 GIS layers. <br />
                    You will be notified via email about the status of the
                    validation and merge processes.
                </p>
            </form>
        </>
    )
}

export default withConfig(UploadForm)
