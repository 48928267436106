import React, { useContext } from 'react'
import { saveAs } from 'file-saver'

import withConfig from '../../wrappers/withConfig'
import toast from '../../elem/Toast'
import { APIRequestContext } from '../../wrappers/APIRequestContext'
import {Link} from 'react-router-dom'

const UploadButton = ({
    style,
    id,
    type,
    name,
    config,
    setShowModal,
}) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    
    const downloadFile = (uploadId) => {
        authenticatedFetch(`${API_URL}/upload/download/${type}/${uploadId}`)
            .then(async (response) => {
                if (response.ok) {
                    return response.blob()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((data) => {
                saveAs(data, `${name}.zip`)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
                setShowModal(true)
            })
    }

    return (
        <div className="linkCell downloads" style={style}>
            <Link
                className={`is-link is-size-7`}
                onClick={() => downloadFile(id)}
                style={{textTransform: "capitalize"}}
            >
                Download {type}
            </Link>
        </div>
    )
}

export default withConfig(UploadButton)
