import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { UserContext } from '../../wrappers/UserContext'
import urls from '../../../utils/constants/urls'

const Login = () => {
    const { user, client } = useContext(UserContext)

    if (user) {
        return (
            <Redirect
                to={{
                    pathname: `/`,
                }}
            />
        )
    }

    return (
        <>
            <h2 className="landing is-size-4">
                Oklahoma NG911 Data Processing
            </h2>
            <section className="section">
                <h2 className="title is-2 has-text-centered">Welcome</h2>
                <div className="login">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-4">
                                <div className="field submit has-text-centered">
                                    <div
                                        className="navbar-item clickable button wb is-large title login-button"
                                        onClick={function () {
                                            client.signinRedirect()
                                        }}
                                    >
                                        Login
                                    </div>
                                </div>
                                <div className="has-text-centered">
                                    <button className="navbar-item clickable button wb is-large title login-button">
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={urls.newRegistration}
                                        >
                                            Sign Up
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <div className="intro">
                    <p>
                        The Oklahoma NG911 Portal is a web based content
                        management tool developed by the Oklahoma Office of
                        Geographic Information (OGI) on behalf of the Oklahoma
                        9-1-1 Management Authority (OK911MA) to support the
                        Oklahoma NG911 Program. It provides a means for members
                        of the OGI, OK911MA, Oklahoma PSAPs and individuals and
                        organizations participating in Oklahoma NG911 Program to
                        facilitate the management and processing of PSAP NG911
                        data. This will also allow participants to stay informed
                        of the various projects and ongoing work being performed
                        by the PSAP’s and contractors. 
                    </p>
                        <p>
                        For more information on the Oklahoma 9-1-1
                            Management Authority, please visit <a rel="noreferrer" target="_blank" href="https://www.ok.gov/911/">their website</a>. For more information on the Oklahoma Next Generation
                            911 (NG911) and Address Standard along with the
                            NG911 GIS Toolkit, please visit <a rel="noreferrer" target="_blank" href="http://www.okmaps.onenet.net/address_standards.htm">our website</a>. For questions about the Oklahoma NG911 Portal,
                            contact <a href="mailto:shellie.willougby@conservation.ok.gov">Shellie Willoughby</a>.
                        </p>
                </div>
        </>
    )
}

export default Login
