import React from 'react'
import { IconContext } from 'react-icons'
import { FaSpinner } from 'react-icons/fa'

class SimpleSpinner extends React.PureComponent {
    render() {
        const { extraClass } = this.props
        return (
            <div className={`spinnerWrapper ${extraClass ? extraClass : ''}`}>
                <IconContext.Provider
                    value={{ className: 'fa-pulse', size: '4em' }}
                >
                    <FaSpinner />
                </IconContext.Provider>
            </div>
        )
    }
}

export default SimpleSpinner
