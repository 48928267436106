import React, { useEffect } from 'react'

import urls from '../../../utils/constants/urls'
import Spinner from '../../elem/Spinner'

const CallbackComponent = () => {
    useEffect(() => {
        const t = setTimeout(() => {
            window.location = urls.home
        }, 5000)
        return () => clearTimeout(t)
    }, [])

    return <Spinner />
}

export default CallbackComponent
