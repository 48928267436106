import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Login from './components/features/user/Login'
import PSAPListPage from './components/features/psap-list/PSAPListPage'
import DetailPage from './components/features/detail-page/DetailPage'
import RestrictedAccess from './components/features/user/RestrictedAccess'
import NavBar from './components/elem/NavBar'
import UserContextProvider from './components/wrappers/UserContext'
import CallbackComponent from './components/features/user/CallbackComponent'
import PSAPContextProvider from './components/wrappers/PSAPContext'
import Header from './components/elem/Header'
import { Toast as ToastContainer } from './components/elem/Toast'
import urls from './utils/constants/urls'
import APIRequestContextProvider from './components/wrappers/APIRequestContext'
import PSAPDetailContextProvider from './components/wrappers/PSAPDetailContext'

const App = () => (
    <React.Fragment>
        <Header />
        <div className="bg"></div>
        <div className="bg bg2"></div>
        <div className="bg bg3"></div>
        <div className="pageContent">
            <Router>
                <Switch>
                    <UserContextProvider>
                        <APIRequestContextProvider>
                            <PSAPContextProvider>
                                <NavBar />
                                <Route exact path={urls.psapDetail}>
                                    <RestrictedAccess
                                        allowedRoles={[
                                            'Admin',
                                            'Read',
                                            'Write',
                                        ]}
                                    >
                                        <PSAPDetailContextProvider>
                                            <DetailPage />
                                        </PSAPDetailContextProvider>
                                    </RestrictedAccess>
                                </Route>
                                <Route exact path={urls.home}>
                                    <RestrictedAccess
                                        allowedRoles={[
                                            'Admin',
                                            'Read',
                                            'Write',
                                        ]}
                                    >
                                        <PSAPListPage />
                                    </RestrictedAccess>
                                </Route>
                            </PSAPContextProvider>
                            <Route path={urls.login} exact component={Login} />
                            <Route
                                path={urls.userCallback}
                                exact
                                component={CallbackComponent}
                            />
                        </APIRequestContextProvider>
                    </UserContextProvider>
                </Switch>
            </Router>
        </div>
        <ToastContainer />
    </React.Fragment>
)

export default App
