import React, { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router'
import { PSAPContext } from './PSAPContext'

const PSAPDetailContext = createContext(null)

const PSAPDetailContextProvider = ({ children }) => {
    const { PSAPs } = useContext(PSAPContext)
    const { PSAPId } = useParams()

    const PSAP = useMemo(() => PSAPs.find(psap => psap.Id === Number(PSAPId)), [PSAPId, PSAPs])

    return (
        <PSAPDetailContext.Provider value={{
            PSAP
        }}>
            {children}
        </PSAPDetailContext.Provider>
    )
}

export { PSAPDetailContext }
export default PSAPDetailContextProvider