import React, { useMemo, useState } from 'react'
import { useTable, useSortBy } from 'react-table'
import { FaAngleUp, FaAngleDown } from 'react-icons/fa'
import DownloadButton from './DownloadButton'
import Modal from '../../elem/Modal'

const UploadTable = ({ uploads, readOnly }) => {
    const [showModal, setShowModal] = useState(false)

    const ActionCell = ({ value, noFinal, name }) => {
        return (
            <>
                <DownloadButton
                    setShowModal={setShowModal}
                    id={value}
                    name={name}
                    type={'original'}
                    style={readOnly ? { display: 'none' } : null}
                />
                <DownloadButton
                    setShowModal={setShowModal}
                    id={value}
                    name={name}
                    type={'merged'}
                    style={noFinal ? { display: 'none' } : null}
                />
            </>
        )
    }

    const DateCell = ({ value }) => value

    const MergeCell = ({ value }) => value === 1 ? 'Pending' : value === 2 ? 'Complete': value === 3 ? 'Failed' : 'N/A'

    const ValidationCell = ({ value }) => value === 1 ? 'Pending' : value === 2 ? 'Valid' : value === 3 ? 'Invalid' : value === 4 ? 'Failed' : 'N/A'

    const createColumns = () => [
        {
            Header: 'Upload Date',
            accessor: 'UploadDate',
            id: 'UploadDate',
            Cell: ({ cell }) => (
                <DateCell
                    value={cell && cell.value ? cell.value.slice(0, 10) : null}
                />
            ),
        },
        { Header: 'File Name', accessor: 'FileNameDisplay' },
        { Header: 'Validation Status', accessor: 'ValidationStatus', Cell: ({ cell }) => (
            <ValidationCell value={cell.value}/>
        ), },
        {
            Header: 'Validation Date',
            accessor: 'ValidationDate',
            Cell: ({ cell }) => (
                <DateCell
                    value={cell && cell.value ? cell.value.slice(0, 10) : null}
                />
            ),
        },
        { Header: 'Merge Status', accessor: 'MergeStatus', Cell: ({ cell }) => (
            <MergeCell value={cell.value}/>
        ), },
        {
            Header: 'Merge Date',
            accessor: 'MergeDate',
            Cell: ({ cell }) => (
                <DateCell
                    value={cell && cell.value ? cell.value.slice(0, 10) : null}
                />
            ),
        },
        { Header: 'Archive Status', accessor: 'ArchiveStatus', Cell: ({ cell }) => (
            <MergeCell value={cell.value}/>
        ), },
        {
            Header: 'Archive Date',
            accessor: 'ArchiveDate',
            Cell: ({ cell }) => (
                <DateCell
                    value={cell && cell.value ? cell.value.slice(0, 10) : null}
                />
            ),
        },
        {
            Header: 'Actions',
            accessor: 'Id',
            Cell: ({ cell, row }) => (
                <ActionCell
                    value={cell.value}
                    name={row.original.FileNameDisplay}
                    noFinal={row.original.MergeDate === 'N/A'}
                />
            ),
        },
    ]



    const formatData = (upload) => {
        upload.forEach((item) => {
            Object.keys(item).forEach((key) => {
                if (item[key] === null) {
                    item[key] = 'N/A'
                }
            })
        })
        return upload
    }

    const columns = useMemo(() => createColumns(), [])
    const tableData = useMemo(() => formatData(uploads), [uploads])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: tableData,
            initialState: {
                sortBy: [
                    {
                        id: 'UploadDate',
                        desc: true,
                    },
                ],
            },
        },
        useSortBy
    )

    return (
        <div className="gridWrapper">
            {showModal ? (
                <Modal
                    isShowing={showModal}
                    className="downloads"
                    toggle={() => setShowModal(false)}
                    header={`File Download Failed`}
                    footer={
                        <button
                            type="button"
                            onClick={() => setShowModal(false)}
                            className="button green"
                        >
                            Ok
                        </button>
                    }
                    body={<p>{`Could not find file.`}</p>}
                />
            ) : null}
            <table {...getTableProps()} className="table is-fullwidth">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    className="has-text-grey-dark"
                                >
                                    {column.render('Header')}
                                    <span className="">
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <FaAngleDown />
                                            ) : (
                                                <FaAngleUp />
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row)
                        return (
                            <tr
                                {...row.getRowProps()}
                                className="row has-text-grey-dark"
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default UploadTable
